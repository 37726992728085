import OpacityParallax from "@components/parallax-opacity"
import ParallaxSticky from "@components/parallax-sticky"
import ScrollPinReveal, { RevealBlock } from "@components/scroll-pin-reveal"
import {
  Block,
  CenterCite,
  CenterText,
  Figure,
  Intro,
  IntroImage,
  NavLink,
  NavLinks,
  StoryHeading,
  StoryHeadingBg,
  StoryMeta,
  StoryTitle,
  TextBlock,
  VideoQuote,
} from "@components/story-components"
import StoryPicker from "@components/storypicker"
import Video from "@components/video"
import YoutubeVideo from "@components/YoutubeVideo/"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import React from "react"
import { FluidImg } from "src/types"

import amirMp4 from "../../assets/amir.mp4"
import amirWebM from "../../assets/amir.webm"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LeftIcon from "../../images/chevron-left.inline.svg"
import RightIcon from "../../images/chevron-right.inline.svg"
import { Text } from "@ui/Typography"

type Props = {
  data: {
    amir: FluidImg
    readingQuran: FluidImg
    jakarta: FluidImg
    amirProfile: FluidImg
  }
}

const IndexPage = ({ data }: Props) => {
  return (
    <Layout>
      <SEO title="Amir's story" />
      <Block>
        <Video
          sources={[
            { src: amirWebM, type: "video/webm" },
            { src: amirMp4, type: "video/mp4" },
          ]}
        />
        <VideoQuote>
          <CenterCite maxWidth="63rem" color="wheat">
            Amir's Story
            <StoryMeta>Indonesia</StoryMeta>
          </CenterCite>
        </VideoQuote>
        <StoryHeading>
          <StoryHeadingBg>
            <NavLinks>
              <NavLink to="/story/khairul">
                <LeftIcon height="1em" strokeWidth="2px" /> Previous
              </NavLink>
              <NavLink to="/story/odin">
                Next story <RightIcon height="1em" strokeWidth="2px" />
              </NavLink>
            </NavLinks>
            <StoryTitle>
              ‘When I was younger, I had no direction in my life’
            </StoryTitle>
          </StoryHeadingBg>
        </StoryHeading>
      </Block>
      <Intro>
        <IntroImage fluid={data.amir.childImageSharp.fluid} />
        <CenterText fontSize={["xl", "2xl"]}>
          I thought of religion as the way to find meaning, and after the first
          Bali bombing, I became interested in jihad. On reflection, I don’t
          think my involvement in the 2009 attack on the JW Marriott and Ritz
          Carlton hotels can be separated from my interest in studying Islam.
        </CenterText>
      </Intro>
      <OpacityParallax
        bg="wine"
        threshold={0.5}
        backgroundProps={{
          fluid: data.readingQuran.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat">
            <CenterText fontSize={["xl", "2xl"]}>
              I met with Islamist groups, and with more radically minded
              clerics. In 2005, I became acquainted with Syaifuddin Zuhri who
              began to directly mentor me. He gave <em>tausiah</em>, or
              religious sermons, and explained how his groups’ actions in the
              name of jihad were justified in religion.
            </CenterText>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <ScrollPinReveal>
        <RevealBlock bg="white" color="wine">
          <CenterCite>
            ‘There was a bond between me and Syaifuddin Zuhri. I was willing to
            contribute my energy, my money, my thoughts, everything to help him
            in his activities’
          </CenterCite>
        </RevealBlock>
        <RevealBlock bg="white" color="cyprus">
          <CenterText>
            Soon Syaifuddin said he deemed me worthy to join in his plans to
            bomb those hotels. We carried out the attack on July 17, 2009. I was
            on the run for about one month before the authorities caught up with
            me.
          </CenterText>
        </RevealBlock>
      </ScrollPinReveal>
      <OpacityParallax
        bg="cyprus"
        threshold={0.5}
        backgroundProps={{
          fluid: data.jakarta.childImageSharp.fluid,
        }}
      >
        <TextBlock color="wheat" mt="120vh">
          <CenterText p="" fontSize={["xl", "2xl"]}>
            Throughout my detention, I was stubborn and maintained that the
            attack was religiously justified. Some of those imprisoned with me
            did not agree with what I had done. The prison also invited several
            knowledgeable clerics to build a dialogue with us. I reflected on my
            actions. I came to understand that I had swallowed radical preaching
            raw before I had learned how to critically consider whether certain
            things were right or wrong. In Indonesia, there are no obstacles to
            worship; we are free. If a country is already safe why should I be
            trying to make it unsafe? I had attacked my own community, exploding
            a bomb resulting in the death of my fellow innocent Muslims.
          </CenterText>
        </TextBlock>
      </OpacityParallax>
      <TextBlock p={undefined} py="5xl" px="xl">
        <SplitScreen>
          <SplitImage fluid={data.amirProfile.childImageSharp.fluid} />
          <Text fontSize={["xl", "2xl"]} px="xl" maxWidth="62ch">
            When I got out of prison people around me were a bit awkward, and a
            little scared. But I had friends and NGOs who gave me advice and
            helped me. When there was a community service I could get involved
            with, I decided to join. By getting involved with the community,
            people started to trust me and see the change in me. They even
            trusted me to lead a prayer at the mosque. This had a positive
            impact on me.
          </Text>
        </SplitScreen>
      </TextBlock>
      <ParallaxSticky>
        <TextBlock bg="viridian" color="wheat">
          <CenterText>
            I feel like we can prevent terrorism by consulting more
            ex-terrorists. We have valuable experiences and knowledge to share.
          </CenterText>
        </TextBlock>
      </ParallaxSticky>
      <TextBlock p="" py="5xl" bg="viridian">
        <Figure>
          <YoutubeVideo videoId="YmA6ea1-4rw" />
        </Figure>
      </TextBlock>
      <StoryPicker />
    </Layout>
  )
}

const SplitImage = styled(GatsbyImage)`
  width: 100%;
  max-width: 30rem;
`

const SplitScreen = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space["3xl"]};
  justify-items: center;

  @media screen and (min-width: 70em) {
    grid-template-columns: minmax(auto, 25rem) minmax(auto, 80ch);
    padding: 0 ${({ theme }) => theme.space["xl"]};
  }
`

export const pageQuery = graphql`
  query {
    amir: file(relativePath: { eq: "amir.jpg" }) {
      ...BlockImage
    }
    readingQuran: file(relativePath: { eq: "amir/reading_quran.jpg" }) {
      ...BlockImage
    }
    jakarta: file(relativePath: { eq: "amir/jakarta.jpg" }) {
      ...BlockImage
    }
    amirProfile: file(relativePath: { eq: "amir/amir_profile.jpg" }) {
      ...BlockImage
    }
  }
`

export default IndexPage
